import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { WalletServicesPlugin } from "@web3auth/wallet-services-plugin";
import { v4 as uuidv4 } from "uuid";

// Use lazy loading for components
const LoginPage = lazy(() => import("./components/LoginPage"));
const HomePage = lazy(() => import("./components/HomePage"));
const BuyerPage = lazy(() => import("./components/BuyerPage"));
const LandingPage = lazy(() => import("./components/LandingPage"));
const UploaderDashboard = lazy(() => import("./components/UploaderDashboard"));
const FileDetailPage = lazy(() => import("./components/FileDetailPage"));

const App = () => {
  const [provider, setProvider] = useState(null);
  const [web3auth, setWeb3auth] = useState(null);
  const [noLoginAccount, setNoLoginAccount] = useState(() => {
    const storedAccount = localStorage.getItem("noLoginAccount");
    return storedAccount ? JSON.parse(storedAccount) : null;
  });

  const createNoLoginAccount = () => {
    const account = {
      id: uuidv4(),
      createdAt: new Date().toISOString(),
    };
    setNoLoginAccount(account);
    localStorage.setItem("noLoginAccount", JSON.stringify(account));
  };

  const handleLogin = (web3authInstance, web3authProvider) => {
    setWeb3auth(web3authInstance);
    setProvider(web3authProvider);
  };

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={<LandingPage onCreateNoLogin={createNoLoginAccount} />}
          />
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
          <Route
            path="/dashboard"
            element={
              provider && web3auth ? (
                <HomePage
                  provider={provider}
                  walletServicesPlugin={WalletServicesPlugin}
                  web3auth={web3auth}
                  noLoginAccount={noLoginAccount}
                />
              ) : noLoginAccount ? (
                <HomePage noLoginAccount={noLoginAccount} />
              ) : (
                <LoginPage onLogin={handleLogin} />
              )
            }
          />
          <Route
            path="/u/:influencerId/:metadataCid?"
            element={<BuyerPage provider={provider} />}
          />
          <Route
            path="/uploaderdashboard"
            element={
              provider && web3auth ? (
                <UploaderDashboard
                  provider={provider}
                  web3auth={web3auth}
                  noLoginAccount={noLoginAccount}
                />
              ) : noLoginAccount ? (
                <UploaderDashboard noLoginAccount={noLoginAccount} />
              ) : (
                <LoginPage onLogin={handleLogin} />
              )
            }
          />
          <Route
            path="/file/:fileCid"
            element={
              <FileDetailPage provider={provider} web3auth={web3auth} />
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
